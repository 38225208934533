/* Fonts */
:root {
	--font-default: "Orbitron", "Open Sans", system-ui, -apple-system, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-primary: "Orbitron", "Montserrat", sans-serif;
	--font-secondary: "Orbitron", "Poppins", sans-serif;
	--font-textual: "Jura", sans-serif;
}

/* Colors */
:root {
	--color-default: #222222;
	--color-primary: #0070c0;
	--color-secondary: #129afb;
	--color-secondary-half: rgba(18, 154, 251, 0.5);
	--color-tertiary: #004372;
	--color-black: #000000;
	--color-white: #fff;
	--color-star: #ffcd3c;
}

/* Smooth scroll behavior */
:root {
	scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	font-family: var(--font-default);
	color: var(--color-default);
}

a {
	color: var(--color-primary);
	text-decoration: none;
}

a:hover {
	color: var(--color-primary);
	text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-default);
}

p,
li,
a,
input,
select,
textarea {
	font-family: var(--font-textual);
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
	padding: 60px 0;
	overflow: hidden;
}

.sections-bg {
	background: var(--color-primary);
	/* background: rgba(255, 117, 7, 0.5); */
}

.sections-white {
	background: var(--color-white);
}

.section-header {
	text-align: center;
	padding-bottom: 60px;
}

.section-header h2 {
	font-size: 32px;
	font-weight: 600;
	padding-bottom: 20px;
	position: relative;
	margin-bottom: 20px;
}

.section-header h2:not(.not-after-dash):after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 3px;
	background: var(--color-primary);
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.section-header-blue h2:not(.not-after-dash):after,
#ferries .section-header-blue .subtitle:after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 3px;
	background: var(--color-black);
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.section-header p {
	margin-bottom: 0;
	color: rgba(0, 0, 0, 0.95);
	font-size: 1.25rem;
}

.section-header-blue p {
	color: #eee;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
	padding: 60px 0 60px 0;
	min-height: 20vh;
	position: relative;
	background-color: var(--color-primary);
}

.breadcrumbs .page-header h2 {
	font-size: 56px;
	font-weight: 500;
	color: #fff;
	font-family: var(--font-secondary);
}

.breadcrumbs .page-header p {
	color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs nav {
	background-color: #f6f6f6;
	padding: 20px 0;
}

.breadcrumbs nav ol {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 600;
	color: var(--color-default);
}

.breadcrumbs nav ol a {
	color: var(--color-primary);
	transition: 0.3s;
}

.breadcrumbs nav ol a:hover {
	text-decoration: underline;
}

.breadcrumbs nav ol li + li {
	padding-left: 10px;
}

.breadcrumbs nav ol li + li::before {
	display: inline-block;
	padding-right: 10px;
	color: var(--color-black);
	content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 15px;
	bottom: -15px;
	z-index: 99999;
	background: var(--color-black);
	width: 44px;
	height: 44px;
	border-radius: 50px;
	transition: all 0.4s;
}

.scroll-top i {
	font-size: 24px;
	color: #fff;
	line-height: 0;
}

.scroll-top:hover {
	/* background: rgba(248, 90, 64, 0.8); */
	background: var(--color-tertiary);
	color: #fff;
}

.scroll-top.active {
	visibility: visible;
	opacity: 1;
	bottom: 15px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
	position: fixed;
	inset: 0;
	z-index: 999999;
	overflow: hidden;
	background: #fff;
	transition: all 0.6s ease-out;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #fff;
	border-color: var(--color-primary) transparent var(--color-primary)
		transparent;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1.5s linear infinite;
	animation: animate-preloader 1.5s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
	[data-aos-delay] {
		transition-delay: 0 !important;
	}
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.topbar {
	background: var(--color-tertiary);
	height: 40px;
	font-size: 14px;
	transition: all 0.5s;
	color: #fff;
	padding: 0;
}

.topbar span {
	letter-spacing: 1px;
}

.topbar .contact-info i {
	font-style: normal;
	color: #fff;
	line-height: 0;
	font-size: 1rem;
}

.topbar .contact-info i a,
.topbar .contact-info i span {
	padding-left: 5px;
	color: #fff;
}

@media (max-width: 575px) {
	.topbar .contact-info i a,
	.topbar .contact-info i span {
		font-size: 13px;
	}
}

.topbar .contact-info i a {
	line-height: 0;
	transition: 0.3s;
}

.topbar .contact-info i a:hover {
	color: #fff;
	text-decoration: underline;
}

.topbar .social-links a {
	color: rgba(255, 255, 255, 0.7);
	line-height: 0;
	transition: 0.3s;
	font-size: 1rem;
}

.topbar .social-links a:hover {
	color: #fff;
}

.header {
	transition: all 0.5s;
	z-index: 997;
	height: 90px;
	background-color: var(--color-primary);
}

.header.sticked {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 70px;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.header .logo img {
	max-height: 40px;
	margin-right: 6px;
}

.header .logo h1 {
	font-size: 1.5rem;
	margin: 0;
	font-weight: 600;
	letter-spacing: 0.8px;
	color: #fff;
	font-family: var(--font-primary);
}

.sticked-header-offset {
	margin-top: 70px;
}

section {
	scroll-margin-top: 70px;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/

.navbar {
	padding: 0;
}

.navbar ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	align-items: center;
}

.navbar li {
	position: relative;
}

.navbar > ul > li {
	white-space: nowrap;
	padding: 10px 0 10px 28px;
}

.navbar a,
.navbar a:focus {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 3px;
	font-family: var(--font-secondary);
	font-size: 16px;
	font-weight: 600;
	color: rgba(255, 255, 255, 0.6);
	white-space: nowrap;
	transition: 0.3s;
	position: relative;
}

.navbar a i,
.navbar a:focus i {
	font-size: 12px;
	line-height: 0;
	margin-left: 5px;
}

.navbar > ul > li > a:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: -6px;
	left: 0;
	background-color: var(--color-tertiary);
	visibility: hidden;
	width: 0px;
	transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
	visibility: visible;
	width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
	color: #fff;
	text-decoration: none;
}

.nav {
	position: fixed;
	background: var(--color-primary);
	color: var(--color-white);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transform: translateX(100%);
	transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
	display: flex;
	justify-content: center;
}

.nav__list {
	list-style: none;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin: 0;
	padding: 0;
}

.nav_list_closed {
	display: none;
}

.nav__link {
	color: inherit;
	font-weight: 900;
	font-size: 1.125rem;
	text-decoration: none;
}

.nav__link:hover,
.nav__link:focus {
	color: var(--color-tertiary);
	text-decoration: underline;
}

.nav-toogle {
	padding: 0.5em;
	background: transparent;
	border: 0;
	cursor: pointer;
	position: relative;
	right: 0em;
	top: 0em;
	z-index: 1000;
}

.hamburger {
	display: block;
	position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
	background: var(--color-white);
	width: 2em;
	height: 3px;
	border-radius: 1em;
	transition: transform 250ms ease-in-out;
}

.nav-toogle-container:hover .hamburger,
.nav-toogle-container:hover .hamburger::before,
.nav-toogle-container:hover .hamburger::after {
	background: var(--color-tertiary);
}

.hamburger::before,
.hamburger::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
}

.hamburger::before {
	top: 6px;
}

.hamburger::after {
	bottom: 6px;
}

.nav_open .nav {
	transform: translateX(0);
}

.nav_open .nav-toggle {
	position: fixed;
}

.nav_open .hamburger {
	transform: rotate(0.625turn);
}

.nav_open .hamburger::before {
	transform: rotate(90deg) translateX(-6px);
}

.nav_open .hamburger::after {
	opacity: 0;
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action .container {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../src/media/cta-bg.jpg") center center;
	background-size: cover;
	padding: 100px 60px;
	border-radius: 15px;
	overflow: hidden;
}

.call-to-action h3 {
	color: #fff;
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 20px;
}

.call-to-action p {
	color: #fff;
	margin-bottom: 20px;
}

.call-to-action .play-btn {
	width: 94px;
	height: 94px;
	margin-bottom: 20px;
	background: radial-gradient(
		var(--color-primary) 50%,
		rgba(0, 131, 116, 0.4) 52%
	);
	border-radius: 50%;
	display: inline-block;
	position: relative;
	overflow: hidden;
}

.call-to-action .play-btn:before {
	content: "";
	position: absolute;
	width: 120px;
	height: 120px;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation: pulsate-btn 2s;
	animation: pulsate-btn 2s;
	-webkit-animation-direction: forwards;
	animation-direction: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: steps;
	animation-timing-function: steps;
	opacity: 1;
	border-radius: 50%;
	border: 5px solid rgba(0, 131, 116, 0.7);
	top: -15%;
	left: -15%;
	background: rgba(198, 16, 0, 0);
}

.call-to-action .play-btn:after {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-40%) translateY(-50%);
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #fff;
	z-index: 100;
	transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.call-to-action .play-btn:hover:before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-40%) translateY(-50%);
	width: 0;
	height: 0;
	border: none;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #fff;
	z-index: 200;
	-webkit-animation: none;
	animation: none;
	border-radius: 0;
}

.call-to-action .play-btn:hover:after {
	border-left: 15px solid var(--color-primary);
	transform: scale(20);
}

.call-to-action .cta-btn {
	font-family: var(--font-primary);
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 12px 48px;
	border-radius: 50px;
	transition: 0.5s;
	margin: 10px;
	border: 2px solid #fff;
	color: #fff;
}

.call-to-action .cta-btn:hover {
	background: var(--color-primary);
	border: 2px solid var(--color-primary);
}

@keyframes pulsate-btn {
	0% {
		transform: scale(0.6, 0.6);
		opacity: 1;
	}

	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}

/*--------------------------------------------------------------
# Our Services Section
--------------------------------------------------------------*/
.services {
	padding: 60px 0;
}

.services .service-item {
	padding: 40px;
	background: #fff;
	height: 100%;
	border-radius: 10px;
}

.services .service-item .icon {
	width: 48px;
	height: 48px;
	position: relative;
	margin-bottom: 50px;
}

.services .service-item .icon i {
	color: var(--color-black);
	font-size: 56px;
	transition: ease-in-out 0.3s;
	z-index: 2;
	position: relative;
}

.services .service-item h3 {
	color: var(--color-default);
	font-weight: 700;
	margin: 0 0 20px 0;
	padding-bottom: 8px;
	font-size: 22px;
	position: relative;
	display: inline-block;
	border-bottom: 4px solid #eeeeee;
	transition: 0.3s;
}

.services .service-item p {
	line-height: 24px;
	font-size: 1.125rem;
	margin-bottom: 0;
}

.services .service-item .readmore {
	margin-top: 15px;
	display: inline-block;
	color: var(--color-primary);
}

.services .service-item:hover .icon i {
	color: var(--color-primary);
}

.services .service-item:hover h3 {
	border-color: var(--color-primary);
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
	padding: 60px 40px;
	box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
	height: 100%;
	position: relative;
	border-radius: 15px;
	border: 3px solid rgba(155, 155, 155, 0.225);
	transition: ease-in-out 350ms all;
}

.background-pricing-item::after {
	content: "";
	opacity: 0.175;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: 90% !important;
}

.background-pricing-item.bg-moto::after {
	background: url("../src/media/moto-bg.png");
	/* margin: 1rem 0 1rem 5rem; */
	background-size: 70% !important;
}

.background-pricing-item.bg-car::after {
	background: url("../src/media/tesla-bg.png");
	/* margin: 12rem 0 5rem 2rem; */
}

.background-pricing-item.bg-minivan::after {
	background: url("../src/media/minivan.png");
	/* margin: 12rem 0 0rem 5rem; */
}

.background-pricing-item.bg-rv::after {
	background: url("../src/media/rv-bg.png");
	/* margin: 5rem 0 5rem 2rem; */
}

.background-pricing-item.bg-yacht::after {
	background: url("../src/media/yacht.png");
	/* margin: 14rem 0 1rem 5rem; */
}

.pricing .pricing-item-white-bg {
	background: #fff;
	transition: ease-in-out 350ms all;
}

.pricing .additional-services {
	column-gap: 2rem;
}

.pricing h3 {
	font-weight: 600;
	font-size: 24px;
	text-align: center;
}

.pricing .icon {
	margin: 30px auto 30px auto;
	width: 70px;
	height: 70px;
	background: var(--color-primary);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
	transform-style: preserve-3d;
}

.pricing .icon i {
	color: #fff;
	font-size: 36px;
	transition: ease-in-out 0.3s;
	line-height: 0;
}

.pricing .icon::before {
	position: absolute;
	content: "";
	height: 86px;
	width: 86px;
	border-radius: 50%;
	/* background: rgba(255, 147, 61, 1); */
	background: var(--color-secondary);
	transition: all 0.3s ease-out 0s;
	transform: translateZ(-1px);
}

.pricing .icon::after {
	position: absolute;
	content: "";
	height: 102px;
	width: 102px;
	border-radius: 50%;
	background: var(--color-secondary-half);
	/* background: rgba(255, 147, 61, 0.5); */
	transition: all 0.3s ease-out 0s;
	transform: translateZ(-2px);
}

.pricing h4 {
	font-size: 48px;
	color: var(--color-black);
	font-weight: 700;
	font-family: var(--font-secondary);
	text-align: center;
}

.pricing h4 sup {
	font-size: 28px;
}

.pricing h4 span {
	color: rgba(108, 117, 125, 0.8);
	font-size: 18px;
	font-weight: 400;
}

.pricing ul {
	padding: 20px 0;
	list-style: none;
	color: #6c757d;
	text-align: left;
	line-height: 20px;
}

.pricing ul li {
	padding: 10px 0;
	display: flex;
	font-size: 18px;
	align-items: center;
}

.pricing ul i {
	color: #059652;
	font-size: 24px;
	padding-right: 3px;
}

.pricing ul .na {
	color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na i {
	color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na span {
	text-decoration: line-through;
}

.pricing .buy-btn {
	display: inline-block;
	padding: 10px 40px;
	border-radius: 50px;
	color: var(--color-primary);
	border: 1px solid var(--color-primary);
	transition: none;
	font-size: 16px;
	font-weight: 600;
	font-family: var(--font-primary);
	transition: 0.3s;
}

.pricing .buy-btn:hover {
	background: var(--color-primary);
	color: #fff;
}

.pricing .pricing-item:hover {
	z-index: 10;
	border: 3px solid var(--color-primary);
}

@media (min-width: 992px) {
	.pricing .pricing-item:hover {
		transform: scale(1.125);
		transition: ease-in-out 500ms all;
	}

	.pricing .pricing-item-white-bg:hover {
		transform: scale(1.075);
		transition: ease-in-out 500ms all;
	}
}

@media (max-width: 991px) {
	.background-pricing-item::after {
		background-size: 70% !important;
	}

	.background-pricing-item.bg-moto::after {
		background-size: 50% !important;
	}
}

/*--------------------------------------------------------------
# Frequently Asked Questions Section
--------------------------------------------------------------*/
.faq .content h3 {
	font-weight: 400;
	font-size: 34px;
	color: var(--color-white);
}

.faq .content h3:after {
	content: "";
	display: block;
	width: 50px;
	height: 3px;
	background: var(--color-black);
	margin-top: 1.125rem;
}

.faq .content h4 {
	font-size: 20px;
	font-weight: 700;
	margin-top: 5px;
}

.faq .content p {
	font-size: 15px;
	color: #6c757d;
}

.faq .accordion--item {
	border: 0;
	box-shadow: 0px 5px 30px 5px rgb(0 0 0 / 10%);
}

.faq .accordion-collapse {
	border: 0;
}

.faq .accordion-button {
	padding: 20px 50px 20px 20px;
	font-weight: 600;
	border: 0;
	font-size: 18px;
	line-height: 24px;
	color: var(--color-default);
	text-align: left;
	background: #fff;
	box-shadow: none;
}

.faq .accordion-button .num {
	padding-right: 10px;
	font-size: 20px;
	line-height: 0;
	color: var(--color-primary);
}

.faq .accordion-button:not(.collapsed) {
	color: var(--color-primary);
	border-bottom: 0;
	box-shadow: none;
}

.faq .accordion-button:after {
	position: absolute;
	right: 20px;
	top: 20px;
}

.faq .accordion-body {
	padding: 0 40px 30px 45px;
	border: 0;
	background: #fff;
	box-shadow: none;
}

.accordion-button-first {
	border-radius: 10px 10px 0px 0px;
}

.accordion-button-last.collapsed {
	border-radius: 0px 0px 10px 10px;
}

.accordion-body-last {
	border-radius: 0 0 10px 10px;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-container {
	background-color: var(--color-primary);
	height: 100%;
	padding: 20px;
	border-radius: 10px 0 0 10px;
	box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.contact .info-item {
	width: 100%;
	/* background-color: rgba(255, 147, 61, 1); */
	background-color: var(--color-secondary);
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 10px;
	color: #fff;
}

.contact .info-item:last-child {
	margin-bottom: 0;
}

.input-labels {
	font-size: 0.8rem;
}

.contact .info-item i {
	font-size: 20px;
	color: #fff;
	float: left;
	width: 44px;
	height: 44px;
	background-color: rgba(255, 255, 255, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
	margin-right: 15px;
}

.contact .info-item h4 {
	padding: 0;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}

.contact .info-item p {
	padding: 0;
	margin-bottom: 0;
	font-size: 14px;
}

.contact .info-item:hover i {
	background: #fff;
	color: var(--color-primary);
}

.contact .php-email-form {
	width: 100%;
	height: 100%;
	background: #fff;
	box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
	padding: 30px;
	border-radius: 0 10px 10px 0;
}

.contact .php-email-form .form-group {
	position: relative;
	align-self: center;
	height: 47px;
	padding-bottom: 8px;
	margin-bottom: 1.25rem;
}

.contact .php-email-form select {
	width: 100%;
	border: 1px solid #ced4da;
	outline: none;
}

.contact .php-email-form select {
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	appearance: none;
}

.contact .php-email-form input,
.contact .php-email-form option,
.contact .php-email-form select,
.contact .php-email-form textarea {
	border-radius: 4px;
	box-shadow: none;
	font-size: 14px;
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="date"],
.contact .php-email-form input[type="time"],
.contact .php-email-form input[type="email"],
.contact .php-email-form input[type="number"],
.contact .php-email-form option,
.contact .php-email-form select,
.contact .php-email-form textarea {
	padding: 12px 15px;
}

.contact .php-email-form input[type="text"]:focus,
.contact .php-email-form input[type="date"]:focus,
.contact .php-email-form input[type="time"]:focus,
.contact .php-email-form input[type="email"]:focus,
.contact .php-email-form input[type="number"]:focus,
.contact .php-email-form select:focus,
.contact .php-email-form textarea:focus {
	border-color: var(--color-primary);
}

.form-control:focus ~ .form__label
/* .form-control:not(:placeholder-shown).form-control:not(:focus) ~ .form__label  */ {
	color: var(--color-primary);
}

.contact .php-email-form button[type="submit"],
.contact .php-email-form button.btn-add-vehicles {
	display: inline-block;
	padding: 10px 40px;
	border-radius: 50px;
	color: var(--color-primary);
	border: 1px solid var(--color-primary);
	transition: none;
	font-size: 16px;
	font-weight: 600;
	font-family: var(--font-primary);
	transition: 0.3s;
	background-color: #fff;
}

.contact .php-email-form button[type="submit"]:hover {
	background: var(--color-primary);
	color: #fff;
}

.contact .php-email-form button.btn-add-vehicles {
	height: auto;
	width: fit-content !important;
	padding: 4px 0px !important;
	border: none;
}

.contact .php-email-form button.btn-remove-vehicle:hover {
	opacity: 75%;
}

.contact .php-email-form .row {
	margin-inline: 0px;
	justify-content: space-between;
}

.contact .php-email-form .row > .form-group {
	padding-left: 0px;
	padding-right: 0px;
}

.PhoneInput {
	height: 47px;
	border: 1px solid #ced4da;
	padding: 0 0 0 15px;
}

.PhoneInputInput {
	border: none;
	height: 100%;
}

.PhoneInput--focus .PhoneInputInput {
	outline: none;
}
.PhoneInput--focus {
	border: 1px solid var(--color-primary);
}

select:invalid,
.not-required {
	color: gray;
}

.form-check-label {
	font-size: 14px;
	color: grey;
	font-family: var(--font-textual);
}

.form-check-label a {
	font-size: 14px;
	color: var(--color-secondary);
}

.form-check-input:checked + .form-check-label {
	color: var(--color-default);
}

.form-check-input:checked + .form-check-label a {
	color: var(--color-primary);
}

@-webkit-keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (min-width: 768px) {
	.contact .php-email-form .row > .form-group {
		width: 49%;
	}
}

.form__label {
	position: absolute;
	top: -0.5rem;
	font-size: 0.8rem;
	left: 8px;
	color: var(--color-black);
	cursor: text;
	padding: 0 0.5rem;
	transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in,
		color 200ms ease-in;
	background-color: var(--color-white);
}

body .show {
	overflow-y: hidden;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

.hero img {
	width: 100%;
	max-width: 500px;
	padding: 2rem 0;
	margin-inline: auto;
}

.hero h2 {
	font-size: 56px;
	font-weight: 700;
	margin-bottom: 10px;
	color: #fff;
}

.hero h6 {
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 25px;
	color: #fff;
}

.hero p {
	color: rgba(255, 255, 255, 0.85);
	font-weight: 400;
	margin-bottom: 30px;
	font-size: 1.25rem;
}

.hero .btn-get-started {
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 14px 40px;
	border-radius: 50px;
	transition: 0.3s;
	color: #fff;
	background: var(--color-secondary);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
	border: 2px solid var(--color-tertiary);
}

.hero .btn-get-started:hover {
	border-color: var(--color-white);
}

.hero .btn-see-rates {
	font-size: 18px;
	transition: 0.5s;
	margin-left: 25px;
	color: #fff;
	font-weight: 600;
}

.hero .btn-see-rates i {
	color: var(--color-white);
	font-size: 32px;
	transition: 0.5s;
	line-height: 0;
	margin-right: 8px;
}

.hero .btn-see-rates:hover i {
	color: var(--color-secondary);
}

@media (min-width: 992px) {
	.hero img {
		margin-inline: 0px;
		padding-bottom: 3rem;
	}
}

@media (max-width: 640px) {
	.hero h2 {
		font-size: 36px;
	}

	.hero .btn-get-started,
	.hero .btn-see-rates {
		font-size: 14px;
	}
}

#benefitBoxes .icon-boxes {
	margin-bottom: -60px;
}

#benefitBoxes .icon-box {
	padding: 60px 30px;
	position: relative;
	overflow: hidden;
	background: var(--color-secondary);
	box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
	transition: all 0.3s ease-in-out;
	border-radius: 8px;
	z-index: 1;
	height: 100%;
	width: 100%;
	text-align: center;
	border: 2px solid var(--color-tertiary);
}

#benefitBoxes.icon-box .title {
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 24px;
}

#benefitBoxes .icon-box .title a {
	color: #fff;
	transition: 0.3s;
}

#benefitBoxes .icon-box .icon {
	margin-bottom: 20px;
	padding-top: 10px;
	display: inline-block;
	transition: all 0.3s ease-in-out;
	font-size: 56px;
	color: rgba(255, 255, 255, 0.6);
	height: 100px;
	width: 100px;
	border: 2px solid rgba(255, 255, 255, 0.6);
	border-radius: 50%;
	box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.6);
}

#benefitBoxes .icon-box:hover {
	border: 2px solid var(--color-white);
}

#benefitBoxes .icon-box:hover .title a,
#benefitBoxes .icon-box:hover .icon {
	color: #fff;
}

#benefitBoxes .icon-box:hover .icon {
	border-color: #fff;
	box-shadow: 0 0 20px 0px rgba(255, 255, 255);
}

/*--------------------------------------------------------------
# Google Maps
--------------------------------------------------------------*/
.google-maps {
	display: flex;
	justify-content: center;
	padding: 2rem 1rem 6rem 1rem;
}

.list-directions li {
	color: #eee;
	margin-bottom: 0.5rem;
}

/*! GALLERY */

#gallery {
	padding-bottom: 0px;
}

#galleryCarousel .carousel-item .container-photo {
	align-self: center;
}

#galleryCarousel .card {
	border: none;
}
#galleryCarousel .img-fluid {
	padding-inline: 10px;
}

#galleryCarousel .carousel-control-prev {
	position: absolute;
	left: -1rem;
}

#galleryCarousel .carousel-control-next {
	position: absolute;
	right: -1rem;
}
#galleryCarousel span {
	color: var(--color-primary);
	font-size: 2rem;
}

#galleryCarousel span:hover {
	color: var(--color-tertiary);
	font-size: 2rem;
}

@media (max-width: 767px) {
	.carousel-inner .carousel-item > div {
		display: none;
	}
	.carousel-inner .carousel-item > div:first-child {
		display: block;
	}
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
	display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
	.carousel-inner .carousel-item-end.active,
	.carousel-inner .carousel-item-next {
		transform: translateX(25%);
	}

	.carousel-inner .carousel-item-start.active,
	.carousel-inner .carousel-item-prev {
		transform: translateX(-25%);
	}

	#gallery .section-header p {
		margin-bottom: 0px;
	}
	#gallery {
		padding-bottom: 30px;
	}
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
	transform: translateX(0);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
	font-size: 14px;
	background-color: var(--color-tertiary);
	padding: 50px 0;
	color: white;
	margin-top: 20px;
}

.footer .footer-info .name {
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 30px;
	font-family: var(--font-primary);
}

.footer .footer-info p {
	font-size: 16px;
}

.footer .social-links a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid rgba(255, 255, 255, 0.2);
	font-size: 16px;
	color: rgba(255, 255, 255, 0.7);
	margin-right: 10px;
	transition: 0.3s;
}

.footer .social-links a:hover {
	color: #fff;
	border-color: #fff;
}

.footer h4 {
	font-size: 16px;
	font-weight: bold;
	position: relative;
	padding-bottom: 12px;
}

.footer .footer-links {
	margin-bottom: 30px;
	padding-top: 5px;
}

.footer .footer-links ul {
	list-style: none;
	padding: 0;
	margin: 0;
	line-height: 0px;
}

.footer .footer-links ul li {
	padding: 10px 0;
	align-items: center;
	font-size: 16px;
}

.footer .footer-links ul li:first-child {
	padding-top: 0;
}

.footer .footer-links ul a {
	color: rgba(255, 255, 255, 0.7);
	transition: 0.3s;
	display: inline-block;
	line-height: 1;
}

.footer .footer-links ul a:hover {
	color: #fff;
}

.footer .footer-contact p {
	line-height: 26px;
	font-size: 16px;
}
.footer .legal-documents {
	font-family: var(--font-textual);
	padding-bottom: 0.5rem;
	text-align: center;
}

.footer .legal-documents a {
	color: var(--color-white);
	text-decoration: underline;
}

.footer .legal-documents a:hover {
	color: var(--color-secondary);
}
.footer .copyright {
	text-align: center;
}

.footer .credits {
	padding-top: 4px;
	text-align: center;
	font-size: 13px;
}

.footer .credits a {
	color: #fff;
}

@media (min-width: 768px) {
	.footer {
		margin-top: 0px;
	}
}

#hero {
	width: 100%;
	height: fit-content;
	padding-top: 0px;
	padding-bottom: 0px;
}

#hero .cover {
	background: url("../src/media/foto_mani_bcg.jpg") center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;

	height: clamp(40rem, 85vh, 100vw);
}

#hero .cover:before {
	content: "";
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}
#hero .main-info {
	height: 90%;
	display: flex;
	align-items: center;
}

#benefitBoxes {
	position: relative;
	top: -170px;
}

/*! CTA */

#CTA .cover {
	background: url("../src/media/van_mercedes.png") center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	height: 100%;
}

#CTA .cover:before {
	content: "";
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}
#CTA h2 {
	padding-top: 60px;
}

#CTA .section-header-blue h2:after {
	background: var(--color-primary);
}

#CTA .section-header p {
	z-index: 1000;
	position: relative;
}

#CTA h4 {
	color: var(--color-white);
}

#CTA .description-destination {
	color: #eee;
	font-size: 18px;
}
#CTA .destinations-list {
	list-style: inside;
	color: #eee;
	font-size: 18px;
	padding-left: 0px;
}

/*! ferries HERO */

#ferries {
	width: 100%;
	height: fit-content;
	padding-top: 0px;
	padding-bottom: 0px;
}
#ferries .cover {
	background: url("../src/media/cruise_ship.jpg") center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	height: clamp(45rem, 85vh, 100vw);
}

#ferries .cover:before {
	content: "";
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#ferries .main-info {
	height: 100%;
	display: flex;
	align-items: center;
}

#ferries .subtitle {
	font-size: 1.5rem;
}

.ferries-text-line-height {
	line-height: 2;
	display: flex;
	align-items: center;
	margin-bottom: 0px;
}

#ferries h2 {
	padding-top: 60px;
	margin-bottom: 0px;
}

/* #ferries .subtitle {
	margin-bottom: 20px;
	position: relative;
	padding-bottom: 20px;
}
#ferries .section-header-blue .subtitle:after {
	background: var(--color-primary);
} */

#ferries h4 {
	color: var(--color-white);
}

.container-benefits-list {
	padding-bottom: 2rem;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding-inline: 0px;
}

.boat-vertical img {
	width: 100%;
	margin-inline: auto;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.benefits-list {
	list-style: inside;
	color: #eee;
	font-size: 18px;
	padding-left: 0px;
	display: flex;
	align-self: center;
	height: 100%;
	padding-right: 0rem;
}

.benefits-list ul {
	color: black;
	padding-left: 0px;
	margin-bottom: 0px;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	font-size: 1.25rem;
}

@media (min-width: 768px) {
	.container-benefits-list {
		flex-direction: row;
		justify-content: space-between;
		align-self: center;
		padding-top: 2rem;
	}

	.benefits-list {
		padding-right: 2rem;
	}

	.benefits-list ul {
		text-align: left;
		font-size: 1.5rem;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#ferries .logo img {
		max-height: 200px;
		padding-left: 1rem;
	}
}

@media (min-width: 992px) {
	#ferries .benefits-list ul {
		height: 250px;
	}
}

.ferries-text-cta {
	margin-top: 0px;
	background: #fff;
	box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
	padding: 30px;
	border-radius: 0 10px 10px 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ferries-text-cta h4 {
	margin-bottom: 20px;
}

.ferries-text-cta button {
	display: inline-block;
	padding: 10px 40px;
	border-radius: 50px;
	color: var(--color-primary);
	border: 1px solid var(--color-primary);
	transition: none;
	font-size: 16px;
	font-weight: 600;
	font-family: var(--font-primary);
	transition: 0.3s;
	background-color: #fff;
}

.ferries-text-cta button:hover {
	background: var(--color-primary);
	color: #fff;
}

/* testimonials */

.testimonials .card {
	border-bottom: 3px var(--color-primary) solid !important;
	transition: 0.5s;
	margin-top: 60px;
}
.testimonials .card .quote {
	font-size: 40px;
	background-color: var(--color-primary);
	color: #ffffff;
	width: 75px;
	height: 75px;
	line-height: 75px;
	margin: -40px auto 0 auto;
}

.testimonials .card .star {
	color: var(--color-star);
}

.testimonials .card p,
.testimonials .card cite {
	font-size: 16px;
}

.toolTip {
	position: relative;
	display: inline-block;
}

.toolTip:hover {
	animation: shake 700ms ease-in-out forwards;
}

.toolTip:hover a i span {
	text-decoration: underline;
}

@keyframes shake {
	0% {
		transform: rotate(1deg);
	}
	50% {
		transform: rotate(-1.5deg);
	}
	70% {
		transform: rotate(1.5deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
